<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="false" />
                <h2 class="page-title">{{ divisions.title }} 상세</h2>
            </div>
        </div>
        <!-- page-body -->
        <div v-if="isReady" class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <div class="board-view-container">
                    <header class="board-header">
                        <h3 class="board-title">{{ params.metaNm }}</h3>
                        <div class="board-meta">&nbsp;</div>
                    </header>

                    <div class="board-body swcc-editor-container" style="margin-top: 16px" v-html="params.metaContents"></div>
                </div>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <!-- //page-bottom -->
    </main>
</template>

<script>
import {computed, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {getItem, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/common.utils';
import ApiService from '@/assets/js/api.service';
import {hideLoading, showLoading} from '@/assets/js/common.alert';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';

export default {
    name: 'DataLakeView',
    components: {CommonBreadcrumb},
    setup: function () {
        showLoading();

        const isReady = ref(false);
        const route = useRoute();
        const router = useRouter();
        // const session = computed(getAuthSession);

        const metaNo = computed(() => (route.params.metaNo ? parseInt(route.params.metaNo) : metaNo));

        const params = reactive({
            metaNo: 0,
            metaNm: '',
            metaContents: '',
        });

        const divisions = reactive({
            title: computed(() => {
                if (route.name.includes('DataLakeOrigin')) {
                    return '데이터 레이크';
                } else if (route.name.includes('DataLakePublic')) {
                    return '퍼블릭 데이터 마트';
                }
                return '';
            }),
        });

        const getAwsMeta = () => {
            if (metaNo.value > 0) {
                ApiService.get('/v1/aws/metas', metaNo.value)
                    .then(res => {
                        hideLoading();
                        if (lengthCheck(res)) {
                            const item = getItem(res);
                            setParams(params, item);
                            isReady.value = true;
                        } else {
                            router.push({name: 'DataLake'});
                        }
                    })
                    .catch(e => {
                        console.error(e);
                        hideLoading();
                    });
            }
        };

        getAwsMeta();

        return {
            isReady,
            params,
            divisions,
            timestampToDateFormat,
        };
    },
};
</script>
